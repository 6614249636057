<template>
    <div>
		<CCard class="p-4">
            <CRow>
                <CCol lg="6">
                        <h5>Audit Logs</h5>
                </CCol>
            </CRow>
            <hr>
            <CRow>
                <CCol lg="2">
                    <div class="form-group">
                        <label for="module">Module <span class="text-danger"> * </span></label>
                        <select id="module" class="form-control" v-model="module_type">
                            <option value="" selected></option>
                            <option value="Booking">Booking</option>
                            <option value="BookingCharges">Booking Charges</option>
                            <option value="Billable">Booking Expenses</option>
                            <option value="ClientBilling">Client Billing</option>
                            <option value="Customer">Customer</option>
                            <option v-if="!is_trucking()" value="DeliveryReceipt">Delivery Receipt</option>
                            <option v-if="!is_trucking()" value="Equipment">Equipment</option>
                            <option v-if="!is_trucking()" value="Furnishing">Furnishing</option>
                            <option v-if="!is_trucking()" value="MaterialTransfer">Material Transfer</option>
                            <option value="Personnel">Personnel</option>
                            <option value="Setting">Settings</option>
                            <option value="User">User</option>
                            <option value="Vehicle">Vehicle</option>
                            <option value="Vendor">Vendor</option>
                        </select>
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <div class="d-flex">
                        <div class="mr-4">
                            <label for="">Filter Type</label>
                            
                            <CDropdown color="info" size="sm" :toggler-text="drpLabel">
                                <CDropdownItem :disabled="isDisabled('Custom')" @click="changeFilter('Custom')">Custom</CDropdownItem>
                                <CDropdownItem :disabled="isDisabled('Today')" @click="changeFilter('Today')">Today</CDropdownItem>
                                <CDropdownItem :disabled="isDisabled('Yesterday')" @click="changeFilter('Yesterday')">Yesterday</CDropdownItem>
                                <CDropdownItem :disabled="isDisabled('Last 7 days')" @click="changeFilter('Last 7 days')">Last 7 days</CDropdownItem>
                                <CDropdownItem :disabled="isDisabled('This month')" @click="changeFilter('This month')">This month</CDropdownItem>
                                <!-- <CDropdownItem :disabled="isDisabled('Last year')" @click="changeFilter('Last year')" href="#">Last year</CDropdownItem>
                                <CDropdownItem :disabled="isDisabled('This year')" @click="changeFilter('This year')" href="#">This year</CDropdownItem> -->
                            </CDropdown>
                        </div>
                        <div class="mr-2">
                            <label>From <span class="text-danger"> * </span></label>
                            <Datepicker input-class="form-control form-control-sm  bg-white" 
                                :typeable="true"
                                v-model="date_from"
                                :format="'MM/dd/yyyy'"
                                @input="event => changeDate(event, 'from')"
                                >
                               
                            </Datepicker>
                            
                        </div>
                        <div class="mr-2">
                            <label>To <span class="text-danger"> * </span></label>
                            <Datepicker input-class="form-control form-control-sm bg-white" 
                                :typeable="true"
                                v-model="date_to"
                                :format="'MM/dd/yyyy'"
                                @input="event => changeDate(event, 'to')">
                            </Datepicker>
                        </div>
                        <div>
                            <label>&nbsp;</label><br>
                            <button class="btn btn-sm btn-success" @click="applyFilter">Apply filter</button>
                        </div>
                        
                    </div>
                    
                </CCol>
            </CRow>
            <CRow class="mt-3">
                <CCol lg="12">
                    <div class="mb-2" v-if="history_log_holder.length || (reference && reference.length)">
                        <label>Search Reference</label>
                        <div class="d-flex">
                            <input class="form-control form-control-sm col-lg-2" placeholder="Enter reference" v-model="reference" />
                            <button class="ml-3 btn btn-sm btn-warning" @click="searchRef">
                                <i class="fa fa-search"></i> Search</button>
                        </div>
                        
                    </div>
                    <CDataTable
							:items="history_log"
							:fields="[
                                {
									key: 'ref_no', 
									label: 'Reference'
								},
								{
									key: 'event', 
									label: 'Action'
								},
								{
									key: 'date', 
									label: 'Date'
								},
								{
									key: 'display_name', 
									label: 'User'
								},
								{
									key: 'old_values',
									label : 'Changes Made'
								},
								
							]"
							hover
							striped
							small
							fixed
							custom
						>   
                            <template #ref_no="{item}">
								<td>
                                    <template v-if="item.record_type">
									    <span>{{ item.ref_no }}</span><br>
                                        <span class="badge badge-sm badge-secondary">{{ item.record_type }}</span>
                                    </template>
                                    <template v-else>
                                        <span>{{ item.ref_no }}</span>
                                    </template>
								</td>
							</template>
							<template #event="{item}">
								<td>
									<span v-if="item.event == 'created'" class="badge badge-sm badge-success">CREATE</span>
                                    <span v-else-if="item.event == 'updated'" class="badge badge-sm badge-warning">UPDATE</span>
                                    <span v-else-if="item.event == 'deleted'" class="badge badge-sm badge-danger">DELETE</span>
								</td>
							</template>
							<template #date="{item}">
								<td>
									{{  item.date }}
								</td>
							</template>
							<template #display_name="{item}">
								<td>
									{{  item.display_name }}
								</td>
							</template>
							<template #old_values="{item}">
								<td v-html="changesTemplate(item.old_values,item.new_values, item.event)">
									
								</td>
							</template>
							
						</CDataTable> 
                        
                        <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(total / limit)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage($event)"
				        />
                </CCol>
            </CRow>
        </CCard>

       
    </div>
</template>

<script setup>
  
</script>

<script>
import Datepicker from 'vuejs-datepicker';
import config from '../config.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import axios from '../axios'

export default {
    name : 'AuditLog',
    components: { Datepicker },
    data() {
        return {
            config,
            drpLabel : 'Custom',
            date_from: null,
            date_to : null,
            module_type : '',
            company_id : config.global_user_data.company_id,
            history_log: [],
            history_log_holder : [],
            currentPage: 1, 
            limit: 20,
            total: 0,
            filter: {},
            reference : null
        }
    },
    computed : {
        isDisabled() {
            return type => {
                return type == this.drpLabel
            }
        },
        changesTemplate() {
			return (value,value2, action) => {
				if(value && action == 'updated') {
					let html = '';
					for (const key in value) {
						if (Object.hasOwnProperty.call(value, key)) {
                            
                            if(!Object.hasOwnProperty.call(value2, key))
                                continue;

							const element = value[key] && value[key] != 'null' ? value[key] : '(empty)';
							const element2 = value2[key] && value2[key] != 'null' ? value2[key] : '(empty)';
							if(key == 'xero_invoice_generated')
								html+=`<span class="font-w800">Xero Invoice Generated</span><br>`;
							else if(key == 'xero_invoice_id')
								html+='';
                            else if(key.toLowerCase() == 'password')
                                html+='<span class="font-w800 text-primary">Password changed</span><br>';
							else
							if(element != element2)
								html+=`<span><span class="text-primary">${key}</span> from <span class="text-danger font-w800">${element}</span> to <span class="text-danger font-w800">${element2}</span></span> <br>`
						}
					}
					html += '';

					return html;
				}
				return ''
			}

			
		},
    },
    methods: {
        changeDate(ev, type) {
            this.drpLabel = 'Custom';
            if(type == 'from')
                this.date_from = moment(ev).format('MM/DD/YYYY')
            else
                this.date_to = moment(ev).format('MM/DD/YYYY')

                console.log(this.date_from)
        },
        searchRef() {
            // const val = ev.target.value;
            // if(!val) {
            //     this.history_log = [...this.history_log_holder];
            // }
            // else {
            //     this.history_log = this.history_log_holder.filter(hg => hg.ref_no.includes(val))
            // }
            this.applyFilter()


        },
        changeFilter(type) {
            this.drpLabel = type;
            switch (type) {
                case 'Custom':
                    this.date_from = null,
                    this.date_to = null;
                    break;
                case 'Today':
                    this.date_from = moment().format('MM/DD/YYYY');
                    this.date_to = moment().format('MM/DD/YYYY');
                    break;
                case 'Yesterday':
                    this.date_from = moment().subtract(1,'days').format('MM/DD/YYYY');
                    this.date_to = moment().subtract(1,'days').format('MM/DD/YYYY');
                    break;
                case 'Last 7 days':
                    this.date_from = moment().subtract(7,'days').format('MM/DD/YYYY');
                    this.date_to = moment().format('MM/DD/YYYY');
                    break;
                case 'This month':
                    this.date_from = moment().subtract(1,'months').format('MM/DD/YYYY');
                    this.date_to = moment().format('MM/DD/YYYY');
                    break;
            
                default:
                    break;
            }

            this.currentPage = 1;
            this.filter = {
                module_type : this.module_type,
                date_from : this.date_from,
                date_to : this.date_to
            }

        },
        updatePage(ev){
			this.currentPage = ev;
			this.applyFilter(false);
		},   
        
        applyFilter(reset_page = true) {
            if(reset_page) {
                this.currentPage = 1;
                this.filter = {
                    module_type : this.module_type,
                    date_from : this.date_from,
                    date_to : this.date_to
                }
            }

            if(!this.filter.module_type) {
               Swal.fire({
                   title: 'Error!',
                   text: "Please provide module.",
                   icon: 'warning', 
               })
               return;
           }
            if(!(this.filter.date_from && this.filter.date_to)) {
               
               Swal.fire({
                   title: 'Error!',
                   text: "Invalid date range.",
                   icon: 'warning', 
               })
               return;
           }

            if(moment(this.filter.date_from).isAfter(moment(this.filter.date_to))) {
               
                Swal.fire({
                    title: 'Error!',
                    text: "Invalid date range.",
                    icon: 'warning', 
                })
                return;
            }
            // if(moment(this.filter.date_to).diff(this.filter.date_from, 'months', true) > 1) {
            //     Swal.fire({
            //         title: 'Error!',
            //         text: "Date range cannot be more than (1) month.",
            //         icon: 'warning', 
            //     })
            //     return;
            // }

            this.$showLoading(true, 'Loading audit logs ...')
            axios.get(`${config.api_path}/audit-log`, {
                params: {
                    company_id : this.company_id,
                    type : this.filter.module_type,
                    from : moment(this.filter.date_from).format('YYYY-MM-DD') + ' 00:00:00',
                    to : moment(this.filter.date_to).format('YYYY-MM-DD') + ' 23:59:59',
                    limit: this.limit,
                    page: this.currentPage,
                    reference: this.reference
                }
            }).then(res => {
                
                this.$showLoading(false);

                const {data, total}  = res.data;
                this.history_log = data.map(l => {
					l.display_name = `${l.firstname} ${l.lastname}`;
					l.date = (l.updated_at) ? moment(l.updated_at).format('MM/DD/YYYY hh:mm A') : '';
					return l;
				});
                this.history_log_holder = [...this.history_log];
                this.total = total;
            }).catch(err => {
                this.$showLoading(false);
                console.error(err)
                Swal.fire({
                    title: 'Error!',
                    text: "Something went wrong.",
                    icon: 'warning', 
                })
            })
        }
    }
}
</script>